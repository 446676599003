import React from 'react';
import { VideoPanel } from './view/videoPanel';
import { BannerPanel } from '../../../cms/items/bannerPanel/view';
import cmsUtils from '../cmsUtils';
import utils from '../../../utils';

export const renderRule = {
  match: (item, cmsOption) => item.itemTypeCode === 'VideoPanel', 
  itemElt: (item, cmsOption) => {
    const useRBonMobile = cmsUtils.payload(item, 'UseRBonMobile') || 'no';
    if(useRBonMobile === 'yes' && utils.site.isMobile()){
      return <BannerPanel item={item} cmsOption={cmsOption} />
    }
    else{
      return <VideoPanel item={item} cmsOption={cmsOption} />
    }
  }
}