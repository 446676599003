import React from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
import { ReactComponent as YourSvg } from './back-base_menu_final-2560px-no-drop-shadow.svg';
//import { ReactComponent as YourSvg2 } from './Front-BaseMenu-DropShadow.svg';
//import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  const links = [
    { url: '/events', title: "What's On", icon: 'whats-on-white.svg', iconHover: 'whats-on-gold.svg' },
    { url: '/menus', title: 'Menus', icon: 'menu-white.svg', iconHover: 'menu-gold.svg' },
    { url: '/#booking', title: 'Book Now', icon: 'book-now-white.svg', iconHover: 'book-now-gold.svg' },
    { url: '/function', title: 'Functions', icon: 'functions-white.svg', iconHover: 'functions-gold.svg' },
    { url: '/bottle-shop', title: 'Bottle Shop', icon: 'bottle-shop-white.svg', iconHover: 'bottle-shop-gold.svg' }
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
        <div className={`icon icon__index__${index}`}>
          <img className="normal" src={`/assets/navicons/${link.icon}`} alt={link.title} />
          <img className="selected" src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  );

  // const bottomMenu = useRef(null);
  // useLayoutEffect(() => {
  //   //console.log('useLayoutEffect');
  //   const slider = new BottomDrawer(bottomMenu.current);
  //   setTimeout(() => slider.init(), 1000)
  // }, [])

  return (
    <>
        <nav className="bottomMenu">
          <ul className="bottomMenu__links">
            {linkElts}
          </ul>
          <YourSvg className='bottom__bg__svg' />
          <div className='dumy__bottom_bg'></div>
        </nav>
    </>
  );
}
