//import React from 'react';
import cmsUtils from '../../../cms/utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';
import Slider from "react-slick";

/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';

export function PageTilesBsyc(props) {
  const item = props.item;
  //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'))
  const pageTileElts = pages.map((page, index) =>
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx={index}></Tile>
  );

  const cssClass = utils.classNames('cms_item', 'pageTiles__new__01', item.cssClass || item.anchorName || '');

  var settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} css={style.pageTiles__new__01__panel}>
      <Slider {...settings}>
        {pageTileElts}
      </Slider>
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  //const imageUrl = "";
   
  const disableLink = props.disableLink;
  //const colorTheme = page.colorTheme;
  const index = props.idx;

  return (
    <div css={style.pageTiles__new__01__tile__container} className="pageTiles__new__01__tile__container">
      <SiteLink className="pageTiles__new__01__tile__link" css={style.pageTiles__new__01__tile__link} to={disableLink ? '' : page.pageUrl}>
        <div css={style.pageTiles__new__01__tile} className={`pageTiles__new__01__tile ${'index__' + index}`}>
          <div css={style.pageTiles__new__01__tile__detail} className="pageTiles__new__01__tile__detail">
            <div css={style.pageTiles__new__01__tile__title} className="pageTiles__new__01__tile__title">
              {/* {page.tileButtonText && page.tileButtonText !== '' && page.tileButtonText} */}
              UPCOMING EVENTS
            </div>
            <div css={style.pageTiles__new__01__tile__sub__title} className="pageTiles__new__01__tile__sub__title">
              { page.pageTitle }
            </div>
            <div css={style.pageTiles__new__01__tile__des} className="pageTiles__new__01__tile__des">
              {page.tileDesc || page.description}
            </div>
          </div>
          <div css={style.pageTiles__new__01__tile__img__container} className="pageTiles__new__01__tile__img__container">
            <div css={style.pageTiles__new__01__tile__img} className="pageTiles__new__01__tile__img" style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
          </div>
        </div>
      </SiteLink>
    </div>
  )
}