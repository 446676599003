import { mq, variables } from '../../../../cssInJs';

export default{
    full__panel__google__map__embed :{
        position: 'relative'
    },
    full__panel__google__map__embed__con : {
        maxWidth: 'unset',
        paddingLeft: 0,
        paddingRight: 0,
        '& > div' : {
            display: 'flex'
        }
    }
}