import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(
    mq({
      //height: [44, null, 80],
      height: [99, null, 150],
      background: "transparent",
      //backgroundImage: 'linear-gradient(rgba(21, 20, 18, 1),rgba(21, 20, 18, 0.8),rgba(21, 20, 18, 0.2))',
      position: "fixed",
      width: "100%",
      zIndex: "999",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "all 0.3s",
    })
  ),
  topScrolled: css(
    mq({
      //height: [44, null, 56],
      background: 'black',
      //backgroundImage: 'linear-gradient(black,black)',
      height: [44, null, 100],
    })
  ),
  logo: css(mq({})),
  logoImg: css(
    mq({
      display: "block",
      height: [70, null, 120],
      //marginTop: ["3rem", null, "3rem"],
      transition: "all .2s",
    })
  ),
  logoScrolled: css(
    mq({
      img: css(
        mq({
          height: [36, null, 80],
          //marginTop: "0rem",
        })
      ),
    })
  ),
};