/** @jsx jsx */
import { jsx } from '@emotion/core'
//import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import  utils from '../../../utils';
import { SiteLink } from '../../../components';
import { getStyle } from './style';

export function SingleBanner(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';
  const alignV = cmsUtils.payload(item, 'AlignV') || '50%';

  const cssClass = utils.classNames('cms_item', 'single-banner', 'banner-item', `bannerSize--${heightSet}`, item.cssClass || item.anchorName || '');
  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, 'ImageUrl'));

  const captionTitle = cmsUtils.payload(item, 'CaptionTitle');
  //const captionTitle2 = cmsUtils.payload(item, 'CaptionTitle2');
  //const caption = cmsUtils.payload(item, 'Caption');
  const overlayColor = cmsUtils.payload(item, 'BgColor') || 'transparent';
  const overlayOpacity = cmsUtils.payload(item, 'OverlayOpacity') || '1.0';

  console.log("overlayOpacity", overlayOpacity, overlayColor);
  
  const style = getStyle({overlayColor : overlayColor, overlayOpacity : overlayOpacity});
  
  const linkUrl = cmsUtils.payload(item, 'LinkUrl2') || cmsUtils.payload(item, 'LinkUrl');

  const titlePanel = captionTitle ? (
    linkUrl ? (
    <SiteLink to={linkUrl} className="banner-item__link">
      <div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>
    </SiteLink>) :
     (<div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>)
  ) : null;

  return (
    <div css={style.single__banner__bg} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl), backgroundPositionY:alignV }}>
      {
        titlePanel &&
        <div className="banner_item__text">{titlePanel}</div>
      }
    </div>
  );

}