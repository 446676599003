//import React from 'react';
import cmsUtils from '../../../cms/utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';
import Slider from "react-slick";
import { useRef } from 'react';

/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';

export function PageTilesBsyc2(props) {
  const item = props.item;
  //const detailHoverBgColor = props.detailHoverBgColor ? props.detailHoverBgColor : "black";
  const cmsOption = props.cmsOption || {};
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'));
  const _length = pages.length;
  const pageTileElts = pages.map((page, index) =>
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit} idx={index}></Tile>
  );

  const slider = useRef(null);

  const cssClass = utils.classNames('cms_item', 'pageTiles__new__02', item.cssClass || item.anchorName || '');
  //centerPadding: '32.9%',

  var settings = {
    //dots: true,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    autoplay: true,
    //slidesToShow: _length <= 3 ? 1 : 3,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'center',
    centerPadding: '34.5%',
    centerMode: true,
  };

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} css={style.pageTiles__new__02__panel}>
      <Slider ref={slider} {...settings}>
        {pageTileElts}
      </Slider>
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const imageUrl2 = utils.site.resourcePath(page.bottomImageUrl) || '';
  //const imageUrl2 = 'https://bridgewaterinn.stg5.jaba.com.au/sites/bridgewaterinn/media/images/places-2.png';
  const disableLink = props.disableLink;
  //const colorTheme = page.colorTheme;
  const index = props.idx;

  return (
    <div css={style.pageTiles__new__02__tile__container} className={`pageTiles__new__02__tile__container ${'index__' + index}`}>
      <SiteLink className="pageTiles__new__02__tile__link" css={style.pageTiles__new__02__tile__link} to={disableLink ? '' : page.pageUrl}>
        <div css={style.pageTiles__new__02__tile__img__container} className="pageTiles__new__02__tile__img__container">
          {/* <div css={style.pageTiles__new__02__tile__img} className="pageTiles__new__02__tile__img" style={{ backgroundImage: utils.css.bgUrlStyle( imageUrl2 && imageUrl2 != '' ? imageUrl2 : imageUrl) }}></div> */}
          <img css={style.pageTiles__new__02__tile__img} className="pageTiles__new__02__tile__img" src={ (imageUrl2 && imageUrl2 !== '') ? imageUrl2 : imageUrl} alt={'abc'} />
        </div>
        <div css={style.pageTiles__new__02__tile__title} className='pageTiles__new__02__tile__title'>
          {page.pageTitle}
        </div>
      </SiteLink>
    </div>
  )
}