//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

//import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {
    pageTiles__new__01__panel: mq({
        marginTop: '20px',
        marginBottom: '20px',
        boxShadow: '2px 5px 12px 0px rgba(0, 0, 0, 0.5)',
        padding: ['1.5rem',null,'1rem'],
        paddingBottom: ['2.5rem',null,'1rem'],
        paddingLeft: ['1rem',null,'0.5rem'],
        paddingRight : ['1rem',null,'0.5rem'],
        '.slick-dots' : mq({
            width: 'auto',
            bottom: ['unset',null,'0px'],
            left: ['0px','20px']
        }),
        '.slick-prev, .slick-next' : {
            display : 'none !important'
        },
        '.slick-dots li button:before' : mq({
            fontSize : ['14px',null,'16px'],
            color : variables.colorDarkGold
        })
    }),

    pageTiles__new__01__tile__container : {
        height: '100%',
        display : 'block'
    },

    pageTiles__new__01__tile__link : {
        height : '100%',
        '&:hover' : {
            textDecoration: 'none',
            '.pageTiles__new__01__tile__title' : {
                color : variables.fontColor
            },
            '.pageTiles__new__01__tile__sub__title' : {
                color: variables.colorDarkGold,
            },
            '.pageTiles__new__01__tile__des' : {
                color : variables.fontColor
            }
        }
    },

    pageTiles__new__01__tile: mq({
        display: 'flex',
        flexDirection: ['column',null,'row'],
        //padding: '1rem',
        flexWrap: ['nowrap',null,'wrap'], //'wrap',
        paddingLeft: '0.5rem',
        paddingRight : '0.5rem',
        height: '100%'
        // '& > div' : {
        //     flex: '1 0 50%',
        //     maxWidth: '50%',
        //     //border: '1px solid red'
        // }
    }),

    pageTiles__new__01__tile__detail: mq({
        //paddingRight:['0',null,'1rem'] ,
        padding : ["0",null,'1.5rem'],
        flex: ['0 1 auto',null,'1 0 45%'],
        maxWidth:['100%',null,'100%'],
        display: 'flex',
        flexDirection: 'column',
        height : ['120px',null,'100%'],
        overflow: 'hidden',
    }),

    pageTiles__new__01__tile__title: mq({
        fontSize: '1.5rem',
        fontWeight: '700',
        fontFamily: variables.familyHeader,
        //marginBottom: ['0.5rem',null,'-0.5rem'] ,
        color : variables.fontColor,
        lineHeight: 1,
        marginBottom : '0.3rem'
    }),

    pageTiles__new__01__tile__sub__title: mq({
        fontFamily: variables.familyHeader,
        color: variables.colorDarkGold,
        fontSize: ['2rem',null,'2.5rem'],
        fontWeight: 700,
        lineHeight: 1
    }),

    pageTiles__new__01__tile__des: {
        marginTop: '1.5rem',
        fontSize: '0.9rem',
        display: '-webkit-box',
        WebkitLineClamp: '5',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color : variables.fontColor,
        flexGrow: 1
    },

    pageTiles__new__01__tile__img__container: mq({
        flex: ['0 1 auto',null,'1 0 55%'],
        maxWidth:['100%',null,'100%'],
        marginTop:['2rem',null,'0']
    }),

    pageTiles__new__01__tile__img: {
        paddingTop: '56.25%',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
    }
}