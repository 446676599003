//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

//import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {
    pageTiles__new__02__panel: mq({
        // marginTop: '20px',
        // marginBottom: '20px',
        // boxShadow: '2px 5px 12px 0px black',
        // padding: '1rem',
        // paddingBottom: ['2rem',null,'1rem'],
        // paddingLeft: '0.5rem',
        // paddingRight : '0.5rem',
        // '.slick-dots' : mq({
        //     width: 'auto',
        //     bottom: ['-24px',null,'0px'],
        // }),
        // '.slick-prev, .slick-next' : {
        //     display : 'none !important'
        // }
        '.slick-prev:before, .slick-next:before': {
            color: 'black'
        },
        '.slick-center': {
            transform: 'scale(1.4)'
        },
        '.slick-list': {
            paddingTop: '2% !important',
            paddingBottom: '2% !important'
        },

        '.slick-slide': {
            transition: 'all 0.5s ease-in-out',
        },
        '.slick-prev' : mq({
            //left: '-30px'
            left : ['20px',null,'-30']
        }),
        '.slick-next' : mq({
            right: ['20px',null,'-30px']
        })
    }),

    pageTiles__new__02__tile__container: {
        // height: '100%',
        // display : 'block'
        margin: '10px',
        padding: '8%',
        //border: '1px solid red'
    },

    pageTiles__new__02__tile__link: {
        '&:hover' : {
            textDecoration: 'none',
            // '.pageTiles__new__01__tile__title' : {
            //     color : variables.fontColor
            // },
            // '.pageTiles__new__01__tile__sub__title' : {
            //     color: variables.colorDarkGold,
            // },
            // '.pageTiles__new__01__tile__des' : {
            //     color : variables.fontColor
            // }
        }
    },

    pageTiles__new__02__tile__img__container: {

    },

    pageTiles__new__02__tile__img: {
        //paddingTop: '56.25%',
        //backgroundPosition: 'center'
        //transition: 'all 0.5s ease-in-out'
    },

    pageTiles__new__02__tile__title: mq({
        textAlign: 'center',
        color: variables.fontColor,
        fontWeight: 700,
        marginTop: '0.2rem',
        fontFamily: variables.familyHeader,
        fontSize: ['0.8rem',null,'1rem']
    })
}